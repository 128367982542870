require('./bootstrap');
require('datatables.net-bs5');
require('datatables.net-responsive-bs5');
require('summernote');
require('jquery-mask-plugin');

window.moment = require('moment');
window.validate = require('jquery-validation');
window.toastr = require('toastr');
window.Swal = require('sweetalert2');
window.datetimepicker = require('daterangepicker');

window.select2 = require('select2');
// require('../../node_modules/select2/dist/js/select2.min.js');

// window.select2 = require('select2-bootstrap-5-theme');
